<template>
    <div class="footer">
        <v-container class="footerContainer">
            <v-row justify="center">
                <p class="footer-disclaimer">
                    PinsDLP est un site de passionnés non-affilié à Disneyland®
                    Paris
                </p></v-row
            >
            <v-row justify="center">
                <a
                    href="https://www.instagram.com/castleofdreamers/"
                    alt="Lien vers le compte instagram de PinsDlp"
                    aria-label="Compte Instagram de PinsDlp"
                    target="_blank"
                >
                    <v-btn
                        class="mr-4 size18 button-color"
                        aria-label="Compte Instagram de PinsDlp"
                        icon="$instagram"
                    >
                    </v-btn>
                </a>
                <a
                    href="https://twitter.com/?lang=en"
                    alt="Lien vers le compte twitter de PinsDlp"
                    aria-label="Compte twitter de PinsDlp"
                    target="_blank"
                >
                    <v-btn
                        icon="$twitter"
                        class="size18 button-color"
                        aria-label="Compte twitter de PinsDlp"
                    ></v-btn
                ></a>
            </v-row>

            <v-row>
                <v-col cols="4">
                    <a href="mailto:contact.pinsdlp@gmail.com">
                        <p class="persoFooterTitle">Contact</p></a
                    ></v-col
                >
                <v-col cols="4">
                    <router-link to="/mentions">
                        <p class="persoFooterTitle">
                            Mentions légales
                        </p></router-link
                    ></v-col
                >
                <v-col cols="4">
                    <router-link to="/plan-du-site">
                        <p class="persoFooterTitle">Plan du site</p>
                    </router-link></v-col
                >
                <!-- <v-col cols="4"
                    ><router-link  to="/sitemap">
                        <p class="persoFooterTitle">Liens</p>
                    </router-link></v-col
                > -->
            </v-row>

            <v-row justify="center">
                <p class="copyright">©2025 - PinsDLP.com</p></v-row
            >
        </v-container>
    </div>
</template>

<script>
export default {
    name: "FooterComponent",
    computed: {},
    methods: {},
};
</script>
